import React from 'react';
import { observable, action, makeObservable } from "mobx"; 
import storageUtil from './storageUtil';
import { Auth, DataAccess, Advance, Clients, Employees, Employeeadvancehistories, User, Bankaccounts, Businessentities, Employeeinfos, Employeecontacts, Employeesalaries, Errors, ContactSubmission, Whatsapp, Agreement, Reporting, IDVerification, Invoice, Ozow, Config } from '../services/requests';
import utils from 'excel4node/distribution/lib/utils';
import { pdf, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import packageJson from '../../package.json';
const Buffer = require('buffer/').Buffer
const base64url = require('js-base64');
const Papa = require('papaparse');
const axios = require('axios');
var FileSaver = require('file-saver');
// var excel = require('excel4node');

// const PDFDocument = require('pdfkit');
// const blobStream = require('blob-stream');

export default class Store{
  // #region Observables
  @observable isLoading = false;
  @observable isLoggedIn = false;
  @observable empLoaded = false;

  @observable unmatched = false;

  @observable allowAdvances = (true);
  @observable advanceHistories = [];
  @observable amountAllowed = 0;
  @observable amountAvailable = 0;
  @observable salaryAmount = 0;
  @observable salaryPaydate = 0;
  @observable electedAdvancePerc = 0;
  @observable advancedAmount = 0;
  @observable prevAdvance = 0;
  @observable clientTransFee = 0;

  @observable bankAccounts = storageUtil.getItem('bnk') || {};
  @observable bankAccountsLoaded = false;

  @observable windowWidth = 0;
  @observable windowHeight = 0;

  @observable auth = (storageUtil.hasItem('usr') && storageUtil.hasItem('tkn')) || false;
  @observable token = storageUtil.getItem('tkn');
  @observable user = storageUtil.getItem('usr') || {};
  @observable passwordAvailable = false;

   @observable employeeDetail = {}
  @observable clientDetail = storageUtil.getItem('cln') || null;
  @observable regDetails = {};

  @observable adminData = {
      isAdmin: false,
      hasAdminData: false,
      viewData: null
  };
  // #endregion
  
  // #region Constructor
  constructor(){
      // makeObservable(this, {
      //     isLoggedIn: observable
      // });
      makeObservable(this);
  }
  // #endregion

  // Functions here

  // #region authentication

  @action logout() {
    this.setToken(null);
    this.setAuth(false);
    storageUtil.clear();
    this.employeeDetail = {};
    this.regDetails = {};
    this.amountAllowed = -1;
    this.amountAvailable = -1;
    this.isLoggedIn = false;
 }

 @action async generatePasswordReset(id){
    let retObj = await Auth.generatePasswordReset(id);

    if (retObj.data !== 'Success'){
        return retObj.data
    }
    return true
}
@action async resetPassword(JWT, token, password){
    let args = {
        token: token,
        password: password
    }
    let retObj = await Auth.resetPassword(JWT, args);
    
    if (retObj.data !== 'Success'){
        return retObj.data
    }else{
        return true
    }
    
}

 @action async register(IDNumber,password){
    if(this.employeeDetail && 
        (this.employeeDetail.id || this.employeeDetail._id) && 
        this.employeeDetail.IDNumber){
        this.setLoading(true);
        
        let args = {
            password: password
        };
        let retObj = await Auth.setPassword(IDNumber, args);
        let user = retObj.data.user;
        let jwt = retObj.data.jwt;
        if (jwt){
            this.setToken(jwt);
        }else{
            return false
        }
        if (!user || !user.username){
            return false
        }
        let newUser = {};
        Object.keys(user).forEach(key => {
            newUser[key] = user[key];
        });
        this.setUser(newUser);
        let userId = user.id || user._id;
        this.setEmployee({user: userId});
        Employees.updateEmployee(IDNumber,{user: userId});

        

        this.setAuth(true);
        this.isLoggedIn = true;
        return true

    } else {
        console.error('Invalid Employee Details: ');
        return 'Invalid Employee Details';
    }
 }

 
 @action async registerWebauthn(IDNumber){

    if(this.employeeDetail && 
        (this.employeeDetail.id || this.employeeDetail._id) && 
        this.employeeDetail.IDNumber){

            let retObj = await Auth.getWebauthn(IDNumber);
            let challengeMakeCred = retObj.data;
            let publicKey = await this.preformatMakeCredReq(challengeMakeCred);
            let nav = await navigator.credentials.create({ publicKey }).then((success) => {
                return success
            }).catch((error) => {
                return false
            });

            if (!nav){
                return 'Failed'
            }
            let credential = nav

            let bufferToBase64url = (buffer) => {
                const byteView = new Uint8Array(buffer);
                let str = "";
                for (const charCode of byteView) {
                    str += String.fromCharCode(charCode);
                }
            
                // Binary string to base64
                const base64String = btoa(str);
            
                // Base64 to base64url
                // We assume that the base64url string is well-formed.
                const base64urlString = base64String.replace(/\+/g, "-").replace(
                    /\//g,
                    "_",
                ).replace(/=/g, "");
                return base64urlString;
            }
    
            const makeCredResponse = {
                authenticatorAttachment: credential.authenticatorAttachment,
                id: credential.id,
                rawId: bufferToBase64url(credential.rawId),
                response: {
                    attestationObject: bufferToBase64url(credential.response.attestationObject),
                    clientDataJSON: bufferToBase64url(credential.response.clientDataJSON)
                },
                type: credential.type
            };
            if (!makeCredResponse.rawId || !makeCredResponse.type || !makeCredResponse.id || makeCredResponse.type !== "public-key"){
                console.log("Missing patameters in response");
                return 'Failed'
            }
            let clientData   = JSON.parse(base64url.decode(makeCredResponse.response.clientDataJSON));
            let original = await this.encode(challengeMakeCred.challenge);
            if (original !== clientData.challenge){
                console.log("Challenges do not match");
                return 'Failed'
            }
            let args = {
                challenge: makeCredResponse
            };
            var resObj;
            try{
                resObj = await Auth.setWebauthn(IDNumber, args);
            }catch(e){
                return false
            }
            
            let jwt = resObj.data.jwt;
            this.setToken(jwt);
            let user = resObj.data.user;
            if (!user || !user._id){
                console.log('Fail');
                return false
            }
            let newUsr = {};
            Object.keys(user).forEach(key => {
                newUsr[key] = user[key];
            });
            
            this.setUser(newUsr);
            let userId = user.id || user._id;
            this.setEmployee({user: userId});
            Employees.updateEmployee(IDNumber,{user: userId});
            this.isLoggedIn = true;
            
            this.setAuth(true);
            return true

            
        }else{
            console.error('Invalid Employee Details: ');
            return 'Invalid Employee Details';
        }
 }

    @action async getQuestions(contact){
        if (this.user?.provide !== 'demo'){
            let args = {
                contact: contact
            }
            this.setLoading(true);
            console.log('getQuestions')
            let retObj = await Auth.getQuestions(this.user.username /*'A1070127'*/, args);
            if (retObj.data === 'Failed to fetch questions.'){
                return false
            }
            if (retObj.data.Question?.Result === 'No Results found.'){
                return 'No Results Found'
            }

            return retObj.data
        }
        return false
    }

    @action async addPassword(data){
        let retObj = await Auth.checkWebauthn(this.employeeDetail.IDNumber);

        if (retObj.data === "User not found"){
            return 'An authentication error has occured.'
        }
        if (retObj.data === "false" || retObj.data === "False" || retObj.data === false){
            return 'An authentication error has occured.'
        }

        let getAssertion = retObj.data.assertion;
        let publicKey = await this.preformatGetAssertReq(getAssertion);
        let nav = await navigator.credentials.get({ publicKey }).then((success) => {
            return success
        }).catch((error) => {
            return false
        });
        if (!nav){
            return 'An authentication error has occured.'
        }
        let credential = nav;
        let bufferToBase64url = (buffer) => {
        
            // modified from https://github.com/github/webauthn-json/blob/main/src/webauthn-json/base64url.ts
            
            const byteView = new Uint8Array(buffer);
            let str = "";
            for (const charCode of byteView) {
                str += String.fromCharCode(charCode);
            }
        
            // Binary string to base64
            const base64String = btoa(str);
        
            // Base64 to base64url
            // We assume that the base64url string is well-formed.
            const base64urlString = base64String.replace(/\+/g, "-").replace(
                /\//g,
                "_",
            ).replace(/=/g, "");
            return base64urlString;
        }

        const getAssertionResponse = {
            authenticatorAttachment: credential.authenticatorAttachment,
            id: credential.id,
            rawId: bufferToBase64url(credential.rawId),
            response: {
                authenticatorData: bufferToBase64url(credential.response.authenticatorData),
                clientDataJSON: bufferToBase64url(credential.response.clientDataJSON),
                signature: bufferToBase64url(credential.response.signature),
                userHandle: bufferToBase64url(credential.response.userHandle),
            },
            type: credential.type
        };
        data.assertionResponse = getAssertionResponse;

        retObj = await Auth.addPassword(this.employeeDetail.IDNumber, data);
        console.log(retObj);
        if (!retObj.data.username){
            return retObj.data
        }
        this.setUser(retObj.data);
        return true
    }
    @action async changePassword(data){
        let retObj = await Auth.changePassword(this.user.username, data);
        if (retObj.data === 'Invalid Password'){
            return false
        }
        if (!retObj.data.username){
            return retObj.data
        }
        this.setUser(retObj.data);
        return true
    }

    @action async addWebauthn(){
        let retObj = await Auth.getWebauthn(this.user.username);
        let challengeMakeCred = retObj.data;
        console.log(challengeMakeCred);
        let publicKey = await this.preformatMakeCredReq(challengeMakeCred)
        console.log(publicKey)

        let nav = await navigator.credentials.create({ publicKey })
        .then((success) => {
            //console.log(success)
            return success
        }).catch((error) => {
            console.log(error);
            return false
        });
        console.log(nav);


        if (!nav){
            console.log('Nav Failed');
            return 'Failed'
        }

        let credential = nav


        let bufferToBase64url = (buffer) => {
        
            // modified from https://github.com/github/webauthn-json/blob/main/src/webauthn-json/base64url.ts
            
            const byteView = new Uint8Array(buffer);
            let str = "";
            for (const charCode of byteView) {
                str += String.fromCharCode(charCode);
            }
        
            // Binary string to base64
            const base64String = btoa(str);
        
            // Base64 to base64url
            // We assume that the base64url string is well-formed.
            const base64urlString = base64String.replace(/\+/g, "-").replace(
                /\//g,
                "_",
            ).replace(/=/g, "");
            return base64urlString;
        }

        const makeCredResponse = {
            authenticatorAttachment: credential.authenticatorAttachment,
            id: credential.id,
            rawId: bufferToBase64url(credential.rawId),
            response: {
                attestationObject: bufferToBase64url(credential.response.attestationObject),
                clientDataJSON: bufferToBase64url(credential.response.clientDataJSON)
            },
            type: credential.type
        };


        if (!makeCredResponse.rawId || !makeCredResponse.type || !makeCredResponse.id || makeCredResponse.type !== "public-key"){
            console.log("Missing patameters in response");
            return 'Failed'
        }
        let clientData   = JSON.parse(base64url.decode(makeCredResponse.response.clientDataJSON));
        let original = await this.encode(challengeMakeCred.challenge);
        if (original !== clientData.challenge){
            console.log("Challenges do not match");
            return 'Failed'
        }
        let args = {
            challenge: makeCredResponse
        };

        retObj = await Auth.addWebauthn(this.user.username, args);
        if (!retObj.data.username){
            return retObj.data
        }
        this.setUser(retObj.data);
        return true

    }

    @action async addWebauthnOld(){
        let retObj = await Auth.getWebauthn(this.employeeDetail.IDNumber);
        let challengeMakeCred = retObj.data;
        // challengeMakeCred.challenge = Uint8Array.from(challengeMakeCred.challenge, c => c.charCodeAt(0));
        // challengeMakeCred.user.id = Uint8Array.from(challengeMakeCred.user.id, c => c.charCodeAt(0));
        let publicKey = await this.preformatMakeCredReq(challengeMakeCred)
        let nav = await navigator.credentials.create({ publicKey })
        .then((success) => {
            console.log(success)
            return success
        }).catch((error) => {
            console.log(error);
            return false
        });

        if (!nav){
            console.log('Nav Failed');
            return 'Failed'
        }
        let credential = nav


        let bufferToBase64url = (buffer) => {
        
            // modified from https://github.com/github/webauthn-json/blob/main/src/webauthn-json/base64url.ts
            
            const byteView = new Uint8Array(buffer);
            let str = "";
            for (const charCode of byteView) {
                str += String.fromCharCode(charCode);
            }
        
            // Binary string to base64
            const base64String = btoa(str);
        
            // Base64 to base64url
            // We assume that the base64url string is well-formed.
            const base64urlString = base64String.replace(/\+/g, "-").replace(
                /\//g,
                "_",
            ).replace(/=/g, "");
            return base64urlString;
        }

        const serializeable = {
            authenticatorAttachment: credential.authenticatorAttachment,
            id: credential.id,
            rawId: bufferToBase64url(credential.rawId),
            response: {
                attestationObject: bufferToBase64url(credential.response.attestationObject),
                clientDataJSON: bufferToBase64url(credential.response.clientDataJSON)
            },
            type: credential.type
        };
        console.log(serializeable)
        let args = {
            challenge: serializeable
        };

        retObj = await Auth.addWebauthn(this.employeeDetail.IDNumber, args);
        if (!retObj.data.username){
            return retObj.data
        }
        this.setUser(retObj.data);
        return true


        return 'Failed'

    }


    @action async validateQuestions(questionId, Question){
        console.log(Question);
        if (this.user.provider === 'demo'){
            return true
        }
        let args = {
            QuestionSetId: questionId.toString(),
            Answers: Question
        }

        let retObj = await Auth.verifyQuestion(args);
        if (retObj.data === 'Success'){
            return true
        }else{
            return false
        }
    }

  @action setToken(token) {
    this.token = token;
    storageUtil.setItem('tkn', token);
    }
  @action async checkWebauthn(IDNumber){

        let retObj = await Auth.checkWebauthn(IDNumber);
        console.log(retObj.data);

        //If user not found
        if (retObj.data === "User not found"){
            return 'nf'
        }

        // If user blocked
        if (retObj.data === 'Blocked'){
            return 'Blocked'
        }

        
        //If no authenticators
        if (retObj.data === "false" || retObj.data === "False" || retObj.data === false){
            return false
        }
        this.passwordAvailable = retObj.data.password;
        let getAssertion = retObj.data.assertion;
        let publicKey = await this.preformatGetAssertReq(getAssertion);
        let nav = await navigator.credentials.get({ publicKey }).then((success) => {
            return success
        }).catch((error) => {
            console.log(error);
            return false
        });
        if (!nav){
            return 'error'
        }
        let credential = nav;
        let bufferToBase64url = (buffer) => {
        
            // modified from https://github.com/github/webauthn-json/blob/main/src/webauthn-json/base64url.ts
            
            const byteView = new Uint8Array(buffer);
            let str = "";
            for (const charCode of byteView) {
                str += String.fromCharCode(charCode);
            }
        
            // Binary string to base64
            const base64String = btoa(str);
        
            // Base64 to base64url
            // We assume that the base64url string is well-formed.
            const base64urlString = base64String.replace(/\+/g, "-").replace(
                /\//g,
                "_",
            ).replace(/=/g, "");
            return base64urlString;
        }

        const getAssertionResponse = {
            authenticatorAttachment: credential.authenticatorAttachment,
            id: credential.id,
            rawId: bufferToBase64url(credential.rawId),
            response: {
                authenticatorData: bufferToBase64url(credential.response.authenticatorData),
                clientDataJSON: bufferToBase64url(credential.response.clientDataJSON),
                signature: bufferToBase64url(credential.response.signature),
                userHandle: bufferToBase64url(credential.response.userHandle),
            },
            type: credential.type
        };


        
        let args = { 
            IDNumber: IDNumber,
            assertionResponse: getAssertionResponse
        }

        let res = await Auth.webauthnLogin(args);

        if (!res.data.user || !res.data.jwt){
            return false
        }

        let usrObj = res.data;
        this.setToken(usrObj.jwt);

        let newUsr = {};
        Object.keys(usrObj.user).forEach(key => {
            newUsr[key] = usrObj.user[key];
        });
        if(newUsr.role === 'Administrator' || newUsr.role.name === 'Administrator' ){
            this.adminData.isAdmin = true;
            this.getAdminData(); // Async, don't need to wait ;)
        }

        newUsr.IDNumber = usrObj.user.username;

        this.getEmployee(IDNumber).then(emp => {
            if(emp){

                let employee = emp;
                if(employee.user){
                    this.setEmployee(employee);
                } 
            }
        });

        this.setUser(newUsr);
        this.setAuth(true);
        this.isLoggedIn = true;
        // reAuthService();
        this.setLoading(false);
        return true;

        
    }

    @action async login(IDNumber, password){
    this.setLoading(true);
    let ret = false;

    let args = {
        IDNumber: IDNumber,
        password: password
    };

    //  let retObj = await Auth.login(args);
    let retObj = await Auth.passwordLogin(args);
    if (retObj.data === 'Blocked'){
        return 'Blocked'
    }
    if (!retObj.data.user || !retObj.data.jwt){
        console.log('fail');
        return false
    }
    console.log(retObj.data)
    
    let usrObj = retObj.data;
    if(usrObj && usrObj.user && usrObj.user.username){
        if(usrObj.jwt){
            this.setToken(usrObj.jwt);
            ret = true;
        }
        let newUsr = {};
        Object.keys(usrObj.user).forEach(key => {
            newUsr[key] = usrObj.user[key];
        });

        // Is Admin
        if(newUsr.role === 'Administrator' || newUsr.role.name === 'Administrator' ){
            this.adminData.isAdmin = true;
            this.getAdminData(); // Async, don't need to wait ;)
        }
        newUsr.IDNumber = usrObj.user.username;
        // Employee Details
        this.getEmployee(IDNumber).then(emp => {
            
            if(emp){

                this.setEmployee(emp);
            }
        });
        this.isLoggedIn = true;
        this.setUser(newUsr);
        this.setAuth(true);

    }else{
        console.log("Login Failed");
    }

    // reAuthService(); // Make sure the service is useing the TKN ;)

    this.setLoading(false);
    return ret;
    }                           

    @action async isRegistered(IDNumber){
        let args = {
            IDNumber: IDNumber
        }
        let retObj = await Auth.isRegistered(args);
        //console.log(retObj.data);
        if (retObj.data === 'Blocked'){
            return 'Blocked'
        }
        if (retObj.data.user){
            this.setUser(retObj.data.user);
            return true
        }else{
            return false
        } 

    }

    @action setRegDetails(data){
        Object.keys(data).forEach(key => {
            this.regDetails[key] = data[key];
        });
    }
    // #endregion

  // #region employee

  @action async createEmployee(data){
    let retObj = await Employees.createEmployee(data);
    return retObj.data
  }

  @action async getAllEmployees(){
    let retObj = await Employees.getEmployees();
    return retObj.data
  }

  @action async getEmployees(idNumber){
        let retObj = await Employees.getEmployee(idNumber);
        if (retObj.data.IDNumber){
            this.setRegDetails({
                IDNumber: retObj.data.IDNumber,
                employee: retObj.data
            });
            this.setEmployee(retObj.data);
            // this.setUnmatched(false)
            return true

        }else{
            this.setUnmatched(true);
            return false
        }
    }

    @action async getEmployeesByBusinessEntity(businessEntity){
        let retObj = await Employees.getEmployeeByBusinessEntity(businessEntity);
        return retObj.data;
    }

  @action async getEmployee(idNumber){
    let empList = [];
    if(idNumber){
        let ret = await Employees.getEmployee(idNumber);
        return ret.data
    }
    return null;
}
@action setEmployee(data){

  Object.keys(data).forEach(key => {
      this.employeeDetail[key] = data[key];
  });
  this.setAdditionalVal();

  storageUtil.setItem('emp', this.employeeDetail);
    }

    @action async loadEmployeeFromPayroll(){
        this.empLoaded = false;
        let retObj;
        if (this.employeeDetail.businessentity.api_name === 'SAP'){
            retObj = await Employees.loadSapInfo(this.employeeDetail.IDNumber);
        }
        else if (this.employeeDetail.businessentity.api_name === 'Sage'){
            retObj = await Employees.loadSageInfo(this.employeeDetail.IDNumber);
        }
        else{
            let now = new Date();
            let sDate = new Date('01 Nov 2022');
            if (now >= sDate){
                retObj = await Employees.loadCSVFee(this.employeeDetail.IDNumber);
            }else{
                return
            }
            
        }
        if (!retObj || retObj.data === 'failed'){
            return
        }
        let employee = retObj.data;
        this.setEmployee(employee);

    }

    @action async updateEmployeeInfo(id, data){
        let retObj = await Employeeinfos.update(id, data);
        if (!retObj.data.IDNumber){
            return false
        }
        this.setEmployeeInfo(retObj.data);
        return true
    }

    @action setEmployeeInfo(data){
        let emp = this.employeeDetail;
        emp.employeeinfo = data;
        this.setEmployee(emp);
    }

    @action async updateEmployeeContact(id, data){
        let retObj = await Employeecontacts.update(id, data);
        if (!retObj.data.IDNumber){
            return false
        }
        this.setEmployeeContact(retObj.data);
        return true

    }

    @action async adminUpdateEmployeeContact(id, data){
        let retObj = await Employeecontacts.update(id, data);
        if (!retObj.data.IDNumber){
            return false
        }
        return true

    }

    @action setEmployeeContact(data){
        let emp = this.employeeDetail;
        emp.employeecontact = data;
        this.setEmployee(emp);
    }

    @action async loadLabournetInfo(id){
        let retObj = await Employees.loadLabourNetInfo(id);
        console.log(retObj.data);
        return retObj.data
    }

    @action async loadPayspaceInfo(id){
        let retObj = await Employees.loadPaySpaceInfo(id);
        console.log(retObj.data);
        return retObj.data
    }
    @action async deleteEmployee(id){
        var retObj;
        try{
            retObj = await Employees.deleteEmployee(id);
            return true
        }catch(e){
            return false
        }
    }

    @action async bulkDeleteEmployees(employees){
        var failed = [];
        var success = [];
        for (let i = 0; i < employees.length; i++){
            let emp = employees[i];
            var retObj;
            try{
                retObj = await Employees.deleteEmployee(emp);
                success.push(emp);
            }catch(e){
                failed.push(emp);
            }
        }
        return {failed : failed, success: success}
    }
  // #endregion

  // #region User
  @action setUser(usr) {
        this.user = usr;
        storageUtil.setItem('usr', usr);
    }

    @action async getAdminUsers(){
        let retObj = await User.getUserByRole('5d8e0b079f51d42bbece9201');
        return retObj.data
    }

    @action async blockUser(id, reason){
        let args = {
            reason: reason
        }
        let retObj = await User.blockUser(id, args);
        return true
    }

    @action async unblockUser(id, reason){
        let args = {
            reason: reason
        }
        let retObj = await User.unblockUser(id, args);
        return true
    }

    @action async createUser(user){
        let retObj = await User.createUser(user);
        if (retObj.data === 'Success'){
            return true
        }else{
            return retObj.data
        }
    }
  // #endregion

  // #region Clients
  @action async getAllClients(){
    let retObj = await Businessentities.getClients();
    return retObj.data
  }

  @action async createClient(client, user){
        let args = {
            client: client,
            user: user
        }

        let retObj = await Businessentities.createClient(args);
        console.log(retObj.data);
        if (retObj.data === 'Success'){
            return true
        }else{
            return false
        }
    }

    @action async parseCSV(file, percentage = 25){
        var result = Papa.parse(file, {header: true, skipEmptyLines: true});
        // console.log(result);
        var rawEmployees = [];
        for (let i = 0; i < result.data.length; i++){
            let employee = result.data[i];
            console.log(employee);
            //IDNumber
            let IDNumber = String(employee.IDNumber) || '';
            IDNumber = IDNumber.toString();
            IDNumber = IDNumber.replace(' ', '');
            IDNumber = IDNumber.replace('\r', '');
            IDNumber = IDNumber.replace('\n', '');
            IDNumber = IDNumber.replace('\t', '');
            IDNumber = IDNumber.replace(',', '.');
            IDNumber = IDNumber.replace(/\s/g, '');

            //FirstName
            let FirstName = String(employee.FirstName) || '';
            FirstName = FirstName.replace(' ', '');
            FirstName = FirstName.replace('\r', '');
            FirstName = FirstName.replace('\n', '');
            FirstName = FirstName.replace('\t', '');
            FirstName = FirstName.replace(',', '.');
            FirstName = FirstName.replace(/\s/g, '');

            //LastName
            let LastName = String(employee.LastName) || '';
            LastName = LastName.replace(' ', '');
            LastName = LastName.replace('\r', '');
            LastName = LastName.replace('\n', '');
            LastName = LastName.replace('\t', '');
            LastName = LastName.replace(',', '.');
            LastName = LastName.replace(/\s/g, '');

            //Job Title
            let JobTitle = String(employee.JobTitle) || '';
            // value = value.replace(' ', '');
            JobTitle = JobTitle.replace('\r', '');
            JobTitle = JobTitle.replace('\n', '');
            JobTitle = JobTitle.replace('\t', '');
            JobTitle = JobTitle.replace(',', '.');

            //Mobile
            let Mobile = String(employee.Mobile) || '';
            Mobile = Mobile.toString();
            Mobile = Mobile.replace(' ', '');
            Mobile = Mobile.replace('\r', '');
            Mobile = Mobile.replace('\n', '');
            Mobile = Mobile.replace('\t', '');
            Mobile = Mobile.replace(',', '.');
            Mobile = Mobile.replace(/\s/g, '');

            //Email
            let Email = String(employee.Email) || '';
            Email = Email.replace(' ', '');
            Email = Email.replace('\r', '');
            Email = Email.replace('\n', '');
            Email = Email.replace('\t', '');
            Email = Email.replace(',', '.');
            Email = Email.replace(/\s/g, '');

            // bankname
            let bankname = String(employee.bankname) || '';
            // value = value.replace(' ', '');
            bankname = bankname.replace('\r', '');
            bankname = bankname.replace('\n', '');
            bankname = bankname.replace('\t', '');
            bankname = bankname.replace(',', '.');

            //accountnumber
            let accountnumber = String(employee.accountnumber) || '';
            accountnumber = accountnumber.toString();
            accountnumber = accountnumber.replace(' ', '');
            accountnumber = accountnumber.replace('\r', '');
            accountnumber = accountnumber.replace('\n', '');
            accountnumber = accountnumber.replace('\t', '');
            accountnumber = accountnumber.replace(',', '.');
            accountnumber = accountnumber.replace(/\s/g, '');

            //branchcode
            let branchcode = String(employee.branchcode)|| '';
            branchcode = branchcode.toString();
            branchcode = branchcode.replace(' ', '');
            branchcode = branchcode.replace('\r', '');
            branchcode = branchcode.replace('\n', '');
            branchcode = branchcode.replace('\t', '');
            branchcode = branchcode.replace(',', '.');
            branchcode = branchcode.replace(/\s/g, '');

            //type
            let type = String(employee.type) || '';
            type = type.replace(' ', '');
            type = type.replace('\r', '');
            type = type.replace('\n', '');
            type = type.replace('\t', '');
            type = type.replace(',', '.');
            type = type.replace(/\s/g, '');

            //Amount
            let Amount = String(employee.Amount) || 0;
            Amount = Amount.toString();
            Amount = Amount.replace(' ', '');
            Amount = Amount.replace('\r', '');
            Amount = Amount.replace('\n', '');
            Amount = Amount.replace('\t', '');
            Amount = Amount.replace(/\s/g, '');
            Amount = Amount.replace(',', '.');
            Amount = Number(Amount);

            //TODO: Calculate Fee Amount
            var actualFee = 3; 
            var actualSubscription = 10;
            // switch(true){
            //     case(Amount <= 10000):
                    // actualFee = 3;
                    // actualSubscription = 10;
            //         break;
            //     case(Amount >= 10001 && Amount <= 20000):
            //         actualFee = 3;
            //         actualSubscription = 29;
            //         break;
            //     case(Amount >= 20001 && Amount <= 40000):
            //         actualFee = 2.5;
            //         actualSubscription = 59;
            //         break;
            //     case(Amount >= 40001 && Amount <= 75000):
            //         actualFee = 2.5;
            //         actualSubscription = 99;
            //         break;
            //     case(Amount > 75000):
            //         actualFee = 2;
            //         actualSubscription = 149;
            //         break;
            //     default:
            //         console.log('default');
            //         break;
            // }

            //PayDate
            let PayDate = String(employee.PayDate) || 25;
            PayDate = PayDate.toString();
            PayDate = PayDate.replace(' ', '');
            PayDate = PayDate.replace('\r', '');
            PayDate = PayDate.replace('\n', '');
            PayDate = PayDate.replace('\t', '');
            PayDate = PayDate.replace(',', '.');
            PayDate = PayDate.replace(/\s/g, '');
            PayDate = Number(PayDate);

            // advPerc
            let advPerc = String(employee.salary_advance_percentage_amount) || percentage;
            advPerc = advPerc.toString();
            advPerc = advPerc.replace(' ', '');
            advPerc = advPerc.replace('\r', '');
            advPerc = advPerc.replace('\n', '');
            advPerc = advPerc.replace('\t', '');
            advPerc = advPerc.replace(',', '.');
            advPerc = advPerc.replace(/\s/g, '');
            advPerc = Number(advPerc);

            let rEmp = {
                employee: {
                    IDNumber: IDNumber,
                    EmployeeNumber: '',
                    api: 'CSV'
                },
                employeeinfo: {
                    IDNumber: IDNumber,
                    FirstName: FirstName,
                    LastName: LastName,
                },
                employeesalary: {
                    IDNumber: IDNumber,
                    ElectedAdvancePerc: advPerc,
                    SalaryFreq: 'M',
                    Amount: Amount,
                    PayDate: PayDate,
                    FeeAmount: actualFee,
                    SubscriptionFee: actualSubscription
                },
                employeecontact: {
                    IDNumber: IDNumber,
                    Mobile: Mobile,
                    Landline: Mobile,
                    PrivateEmail: Email,
                    WorkEmail: Email
                },
                homeAddress: {
                    IDNumber: IDNumber,
                    Line1: '',
                    Line2: '',
                    Line3: '',
                    City: '',
                    Province: '',
                    Country: '',
                    PostalCode: '',
                    Type: 'Residential'
                },
                workAddress: {
                    IDNumber: IDNumber,
                    Line1: '',
                    Line2: '',
                    Line3: '',
                    City: '',
                    Province: '',
                    Country: '',
                    PostalCode: '',
                    Type: 'Work'
                },
                bankaccount: {
                    IDNumber: IDNumber,
                    bankname: bankname,
                    accountnumber: accountnumber,
                    branchcode: branchcode,
                    type: type
                }
            }
            // console.log(rEmp);

            rawEmployees.push(rEmp);
        }
        return rawEmployees
    }

    @action async checkIfCSVExists(employees){
        let args = {
            employees: employees
        }

        let retObj = await Employees.checkIfCSVExists(args);
        return retObj.data;
    }

    @action async uploadCSV(employees, businessentity){
        let args = {
            employees: employees,
            businessEntity: businessentity
        }

        let retObj = await Employees.uploadCSV(args);
        console.log(retObj.data);

        return true;
    }

    @action async updateClientById(id, data){
        let retObj = await Businessentities.updateClientById(id, data);
        if (retObj.data === 'Success'){
            return true
        }else{
            return false
        }
    }

    @action async testLabournet(data){
        let retObj = await Businessentities.testLabournet(data);
        return retObj.data
    }

    @action async testPaySpace(data){
        let retObj = await Businessentities.testPaySpace(data);
        return retObj.data
    }

  // #endregion

    // #region Employee Salary
    @action async updateEmployeeSalary(id, data){
        let retObj = await Employeesalaries.updateSalary(id, data);
        // console.log(retObj.data);
        return true
    }
    // #endregion

  // #region Advance


    @action async getAdvancesForClient(clientId){
        let retObj = await Advance.getAdvanceHistoryForClient(clientId);
        return retObj.data
    }

    @action async getAdvances(){
        let retObj = await Advance.getAdvances();
        return retObj.data
    }

    @action async checkOzowPayoutStatus(id){
        let retObj = await Advance.checkOzowPayoutStatus(id);
        return retObj.data
    }

    @action async getAdvanceForClientAndDate(id, fromDate, toDate){
        let args = {
            fromDate: fromDate,
            toDate: toDate
        }

        let retObj = await Advance.getAdvancesForClientAndDate(id, args);
        console.log(retObj.data);
        return retObj.data
    }
  @action async setAdditionalVal(){
        if(this.employeeDetail && this.employeeDetail.employeesalary){
            this.salaryAmount = this.employeeDetail.employeesalary.Amount || 0;
            this.electedAdvancePerc = this.employeeDetail.employeesalary.ElectedAdvancePerc || 0;
            this.salaryPaydate = this.employeeDetail.employeesalary.PayDate || 25;
            let businessentity = this.employeeDetail.businessentity || null;

            if(businessentity && (businessentity._id || businessentity.id)){
                let clientDetail = businessentity;//await Businessentities.get((businessentity._id || businessentity.id));
                if(clientDetail){
                    storageUtil.setItem('cln', clientDetail);
                    this.clientDetail = clientDetail;
                }
            }

            if(this.employeeDetail && this.employeeDetail.priceplan && this.employeeDetail.priceplan._id){
                // Ignore ;)
            } else {
            }
            this.empLoaded = true;
        }
    }
@action async getAdvanceHistory(){
    let retObj = await Employeeadvancehistories.getHistory(this.user.username);

    if (retObj.data.updated !== false && retObj.data.updated.IDNumber){
        this.setEmployee(retObj.data.updated)
    }
    if (retObj.data.fee !== -1){
        this.employeeDetail.employeesalary.FeeAmount = retObj.data.fee;
    }

    if (retObj.data.subscription !== -1){
        this.employeeDetail.employeesalary.SubscriptionFee = retObj.data.subscription;
    }

    this.amountAllowed = parseFloat(retObj.data.allowed);
    this.amountAvailable = parseFloat(retObj.data.available);
    this.advanceHistories = retObj.data.history;
    return {
        amountAllowed: retObj.data.allowed,
        amountAvailable: retObj.data.available,
        history: retObj.data.history
    }
}

    @action async loadAdvanceHistory(IDNumber){
        let retObj = await Employeeadvancehistories.getHistory(IDNumber);

        return {
            amountAllowed: retObj.data.allowed,
            amountAvailable: retObj.data.available,
            history: retObj.data.history
        }
    }

    @action async processAdminManualAdvance(id, data){
        let retObj = await Advance.processManual(id, data);
        return true
    }
    @action async processAdminOzowAdvance(id, data){
        let retObj = await Advance.processManualOzow(id, data);
        if (retObj.data === 'Failed to process transaction.'){
            return false
        }
        return true
    }

    @action async adminUpdateTransactionStatus(data){
        let retObj = await Advance.updateTransactionStatus(data);
        return true
    }

    @action async authenticateAdvanceWebauthn(){
        let retObj = await Auth.checkWebauthn(this.employeeDetail.IDNumber);
        console.log(retObj.data);
        if (retObj.data === "User not found"){
            return false
        }
        if (retObj.data === 'Blocked'){
            return false
        }

        if (retObj.data === "false" || retObj.data === "False" || retObj.data === false){
            return 'Password'
        }
        let getAssertion = retObj.data.assertion;
        let publicKey = await this.preformatGetAssertReq(getAssertion);
        let nav = await navigator.credentials.get({ publicKey }).then((success) => {
            return success
        }).catch((error) => {
            console.log(error);
            return false
        });
        if (!nav){
            return false
        }
        let credential = nav;
        let bufferToBase64url = (buffer) => {
            const byteView = new Uint8Array(buffer);
            let str = "";
            for (const charCode of byteView) {
                str += String.fromCharCode(charCode);
            }
            const base64String = btoa(str);
            const base64urlString = base64String.replace(/\+/g, "-").replace(
                /\//g,
                "_",
            ).replace(/=/g, "");
            return base64urlString;
        }
        const getAssertionResponse = {
            authenticatorAttachment: credential.authenticatorAttachment,
            id: credential.id,
            rawId: bufferToBase64url(credential.rawId),
            response: {
                authenticatorData: bufferToBase64url(credential.response.authenticatorData),
                clientDataJSON: bufferToBase64url(credential.response.clientDataJSON),
                signature: bufferToBase64url(credential.response.signature),
                userHandle: bufferToBase64url(credential.response.userHandle),
            },
            type: credential.type
        };
        let args = { 
            IDNumber: this.employeeDetail.IDNumber,
            assertionResponse: getAssertionResponse
        }
        let res = await Auth.webauthnLogin(args);

        if (!res.data.user || !res.data.jwt){
            return false
        }

        return true
    }

    @action async authenticateAdvancePassword(password){
        let args = {
            IDNumber: this.employeeDetail.IDNumber,
            password: password
        };
    
        let retObj = await Auth.passwordLogin(args);
        console.log(retObj.data);
        if (retObj.data === 'Blocked'){
            return false
        }
        if (!retObj.data.user || !retObj.data.jwt){
            return false
        }else{
            return true
        }
    }

    @action async processBankAccountAdvance(data){
        let retObj = await Advance.processBankAccount(this.employeeDetail.IDNumber, data);
        console.log(retObj.data);
        return retObj.data
    }


    @action async processAdvanceWebauthn(data){
        let retObj = await Auth.checkWebauthn(this.employeeDetail.IDNumber);

        //If user not found
        if (retObj.data === "User not found"){
            return 'An authentication error has occured'
        }

        //If no authenticators
        if (retObj.data === "false" || retObj.data === "False" || retObj.data === false){
            return 'Password'
        }

        let getAssertion = retObj.data.assertion;
        let publicKey = await this.preformatGetAssertReq(getAssertion);
        let nav = await navigator.credentials.get({ publicKey }).then((success) => {
            return success
        }).catch((error) => {
            return false
        });
        if (!nav){
            return 'error'
        }

        let credential = nav;
        let bufferToBase64url = (buffer) => {
        
            // modified from https://github.com/github/webauthn-json/blob/main/src/webauthn-json/base64url.ts
            
            const byteView = new Uint8Array(buffer);
            let str = "";
            for (const charCode of byteView) {
                str += String.fromCharCode(charCode);
            }
        
            // Binary string to base64
            const base64String = btoa(str);
        
            // Base64 to base64url
            // We assume that the base64url string is well-formed.
            const base64urlString = base64String.replace(/\+/g, "-").replace(
                /\//g,
                "_",
            ).replace(/=/g, "");
            return base64urlString;
        }

        const getAssertionResponse = {
            authenticatorAttachment: credential.authenticatorAttachment,
            id: credential.id,
            rawId: bufferToBase64url(credential.rawId),
            response: {
                authenticatorData: bufferToBase64url(credential.response.authenticatorData),
                clientDataJSON: bufferToBase64url(credential.response.clientDataJSON),
                signature: bufferToBase64url(credential.response.signature),
                userHandle: bufferToBase64url(credential.response.userHandle),
            },
            type: credential.type
        };
        data.assertionResponse = getAssertionResponse;

        let result = await this.processAdvance(data);
        return result
    }
    @action async processAdvance(data){
        let retObj = await Advance.process(this.employeeDetail.IDNumber, data);
        return retObj.data
    }
  // #endregion
  
    // #region Contact
    @action async createContactSubmission(data){
        let retObj = await ContactSubmission.createContactSubmission(data);
        return true
    }

    @action async getContactSubmissionById() {
        let retObj = await ContactSubmission.getContactSubmissionById(this.user.username);
        return retObj.data
    }

    @action async updateContactSubmissionById(id, data) {
        let retObj = await ContactSubmission.updateContactSubmissionById(id, data);
        return retObj.data
    }

    @action async getContactSubmissions() {
        let retObj = await ContactSubmission.getContactSubmissios();
        return retObj.data
    }
    // #endregion

  // #region data access
  @action async dataAccessPermission(){
    //Start loading data 
    // this.loadEmployeeFromPayroll(); //async func will run in background
    //Get IP
    let instance = axios.create({
        baseUrl: 'https://api.ipify.org', 
        baseURL: 'https://api.ipify.org'
    })
    let ip = (await instance.get()).data;
    //Date and Time
    let dateTime = new Date();
    //Browser setails
    let browser = window.navigator.userAgent;
    //Location
    let config = {
        method: 'get',
        url: `http://www.geoplugin.net/json.gp?ip=${ip}`,
        headers: {}
    }
    let lat, lon, reason = '';
    try {
        let location = await axios(config);
        lat = location.data.geoplugin_latitude || '';
        lon = location.data.geoplugin_longitude || '';
    }catch(e){
        lat = '';
        lon = '';
        reason = 'Failed to get location from IP';
    }
    /* let lat, lon, reason;
    let location = await this.getLocation()
    .then((pos) => {
        lat = pos.coords.latitude.toString();
        lon = pos.coords.longitude.toString();
        reason = 'User did give permission to use location';
    })
    .catch((err) => {
        lat = '';
        lon = '';
        reason = 'User did not give permission to use location';
    }); */
    let dataPermission = {
        ip: ip,
        dateTime: dateTime,
        browser: browser,
        IDNumber: this.user.username,
        latitude: lat,
        longitude: lon,
        locationReason: reason,
        permission: true
    }
    let retObj = await DataAccess.createDataAccessPermission(dataPermission);
    if (retObj.data === "Success"){
        return true
    }else{
        return false
    }
}

    


  // #endregion
  
  // #region Bank Accounts
  @action async loadBankAccounts(){
        let retObj = await Bankaccounts.getBankAccounts(this.user.username);

        if (!retObj.data){
            return false
        }

        this.setBankAccounts(retObj.data);
        return true
    }


    @action setBankAccounts(data){
        storageUtil.setItem('bnk', data);
    }

    @action retrieveBankAccounts(){
        let bankaccount = storageUtil.getItem('bnk');
        return bankaccount
        
    }

    @action async getBankAccountsByID(id){
        let retObj = await Bankaccounts.getBankAccounts(id);
        return retObj.data
    }

    @action async createBankAccount(acc){

        let bankaccount = await Bankaccounts.createBankAccount(acc);
        if (bankaccount.status !== 200){
            return false
        }
        return true
    }

    @action async updateBankAccount(data){
        let retObj = await Bankaccounts.updateBankAccount(data);
        return true
    }

    @action async addBankAccount(data){
        let retObj = await Bankaccounts.addBankAccount(this.employeeDetail.IDNumber, data);
        if (retObj.data === 'Verification Failed'){
            return false
        }
        this.employeeDetail = retObj.data;
        return true
    }
  // #endregion
  
  // #region Other
  @action clearStorage(){
    // Clear Storage
    storageUtil.clear();
    this.setAuth(false);
  }

  @action setLoading(val) {
    this.isLoading = val;

    // Stop after 15 sec
    if(val){
        if(this.loadingInt){
            try{
               clearTimeout(this.loadingInt);
            }catch{}
            this.loadingInt = null;
        }
        let _this = this;
        this.loadingInt = setTimeout(() => {
            _this.isLoading = false;
        }, 60*1000);
    }
  }

  @action setAuth(status){
    this.auth = status;
  }
  // #endregion

  // #region Webauthn Helpers
  @action async preformatGetAssertReq(getAssert){
    getAssert.challenge = await this.decode(getAssert.challenge);
    
    for(let allowCred of getAssert.allowCredentials) {
        allowCred.id = await this.decode(allowCred.id);
    }

    return getAssert
}

@action async publicKeyCredentialToJSON(pubKeyCred){
    if(pubKeyCred instanceof Array) {
        let arr = [];
        for(let i of pubKeyCred)
            arr.push(await this.publicKeyCredentialToJSON(i));

        return arr
    }

    if(pubKeyCred instanceof ArrayBuffer) {
        return await this.encode(pubKeyCred)
    }

    if(pubKeyCred instanceof Object) {
        let obj = {};

        for (let key in pubKeyCred) {
            obj[key] = await this.publicKeyCredentialToJSON(pubKeyCred[key])
        }

        return obj
    }

    return pubKeyCred
  }


@action async encode(arraybuffer){
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';

    // Use a lookup table to find the index.
    let lookup = new Uint8Array(256);
    for (let i = 0; i < chars.length; i++) {
        lookup[chars.charCodeAt(i)] = i;
    }
    let bytes = new Uint8Array(arraybuffer),
    i, len = bytes.length, base64url = '';

    for (i = 0; i < len; i+=3) {
        base64url += chars[bytes[i] >> 2];
        base64url += chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
        base64url += chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
        base64url += chars[bytes[i + 2] & 63];
    }

    if ((len % 3) === 2) {
        base64url = base64url.substring(0, base64url.length - 1);
    } else if (len % 3 === 1) {
        base64url = base64url.substring(0, base64url.length - 2);
    }

    return base64url;
  }

  @action async decode(base64string) {
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';

    // Use a lookup table to find the index.
    let lookup = new Uint8Array(256);
    for (let i = 0; i < chars.length; i++) {
        lookup[chars.charCodeAt(i)] = i;
    }

    let bufferLength = base64string.length * 0.75,
    len = base64string.length, i, p = 0,
    encoded1, encoded2, encoded3, encoded4;

    let bytes = new Uint8Array(bufferLength);

    for (i = 0; i < len; i+=4) {
        encoded1 = lookup[base64string.charCodeAt(i)];
        encoded2 = lookup[base64string.charCodeAt(i+1)];
        encoded3 = lookup[base64string.charCodeAt(i+2)];
        encoded4 = lookup[base64string.charCodeAt(i+3)];

        bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
        bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
        bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
    }

    return bytes.buffer
  }


 @action async preformatMakeCredReq(makeCredReq){
    makeCredReq.challenge = await this.decode(makeCredReq.challenge);
    makeCredReq.user.id = await this.decode(makeCredReq.user.id);

    return makeCredReq
}

// #endregion 
 
    // #region Logs
    @action async getErrors(){
        let retObj = await Errors.getErrors();
        return retObj.data
    }

    @action async updateError(id, data){
        let dataString = JSON.stringify(data);
        let args = {
            Comments: dataString
        }
        let retObj = await Errors.updateErrors(id, args);
        return true

    }
    // #endregion

    // #region Reporting
    @action async getUserSignUpsByDate(from, to){
        let args = {
            fromDate: from,
            toDate: to
        }

        let retObj = await Reporting.getUserSignUpsPerDate(args);
        
        return retObj.data
    }
    // #endregion

    // #region Agreement
    @action async createAgreement(){
        //Get IP
        let instance = axios.create({
            baseUrl: 'https://api.ipify.org', 
            baseURL: 'https://api.ipify.org'
        })
        let ip = (await instance.get()).data;
        //Date and Time
        let dateTime = new Date();
        //Browser setails
        let browser = window.navigator.userAgent;
        //Location
        let config = {
            method: 'get',
            url: `http://www.geoplugin.net/json.gp?ip=${ip}`,
            headers: {}
        }
        let lat, lon, reason = '';
        try {
            let location = await axios(config);
            lat = location.data.geoplugin_latitude || '';
            lon = location.data.geoplugin_longitude || '';
        }catch(e){
            lat = '';
            lon = '';
            reason = 'Failed to get location from IP';
        }
        let dataPermission = {
            ip: ip,
            dateTime: dateTime,
            browser: browser,
            IDNumber: this.user.username,
            latitude: lat,
            longitude: lon,
            locationReason: reason,
            agreed: true
        }
        let retObj = await Agreement.createAgreement(dataPermission);
        if (retObj.data === "Success"){
            return true
        }else{
            return false
        }
    }
    // #endregion

    // #region IDVerification
    @action async getIDVerificationById(id){
        let retObj = await IDVerification.getIDVerificationByID(id);
        return retObj.data
    }

    @action async createIDVerification(id, data){
        let retObj = await IDVerification.createIDVerification(id, data);
        return true
    }
    // #endregion

    // #region Invoice
    @action async createInvoice(employeeAdvances, client, invoiceNumber, startDate, endDate, advanceAmount, feeAmount){
        let memAmount = Number(employeeAdvances.length) * 10;
        let taxAmount = Number(memAmount) * 15 / 100;
        let totalExcl = Number(advanceAmount) + Number(feeAmount) + memAmount;
        let totalIncl = Number(totalExcl) + Number(taxAmount);
        let invoice = {
            StartDate: new Date(startDate),
            EndDate: new Date(endDate),
            InvoiceNumber: invoiceNumber,
            Status: 'Pending',
            Businessentity: client._id,
            Advances: employeeAdvances, 
            AdvanceAmount: advanceAmount,
            FeeAmount: feeAmount,
            MembershipAmount : memAmount,
            VAT: taxAmount,
            TotalExcl: totalExcl,
            TotalIncl: totalIncl,
            InvoiceDate: new Date()
        }

        let retObj = await Invoice.createInvoice(invoice);
        // this.exportExcelStatement(employeeAdvances, client, invoiceNumber)
        return true
    }

    @action async getInvoices(){
        let retObj = await Invoice.getInvoices();
        return retObj.data
    }

    @action async updateInvoice(id, data) {
        let retObj = await Invoice.updateInvoice(id, data);
        return retObj.data
    }

    @action async getInvoicesByClient(id){
        let retObj = await Invoice.getInvoicesByClient(id);
        return retObj.data
    }
    // #endregion

    // #region Whatsapp
    @action async getWhatsappToken(){
        let retObj = await Whatsapp.getWhatsappToken(this.user.username);
        return retObj.data
    }
    // #endregion

    // #region TsCs
    @action async downloadTsCs(){
        window.open('https://levelfinance.azureedge.net/siteassets/TermsAndConditions.pdf', '_blank');
        
    }
    // #endregion 

    // #region Agreement
    @action async downloadAgreement(){
        window.open('https://levelfinance.azureedge.net/siteassets/Agreement.pdf', '_blank');
    }
    // #endregion

    // #region Privacy Policy
    @action async downloadPrivacyPolicy(){
        window.open('https://levelfinance.azureedge.net/siteassets/PrivacyPolicy.pdf', '_blank');
    }
    // #endregion

    // #region Ozow
    @action async getOzowBalance(){
        var retObj;
        try{
            retObj = await Ozow.getBalance();
            console.log(retObj.data);
            return retObj.data
        }catch(e){
            return 'error'
        }
    }
    // #endregion

    // #region Config
    @action async checkAppVersion(){
        let appVersion = packageJson.version;
        var response;
        try{
            response = await Config.getAppVersion();
            let mVersion = response.data;
            let appVerArr = appVersion.split('.');
            let mVer = mVersion.split('.');
            for (let i = 0; i < appVerArr.length; i++){
                if (appVerArr[i] === mVer[i]){
                    continue;
                }
                if (appVerArr[i] > mVer[i]){
                    return true
                }
                if (appVerArr[i] < mVer[i]){
                    return false
                }
            }
            return true
            
        }catch(e){
            return true
        }
    }

    // #endregion
}

// #region Store Config
const StoreContext = React.createContext();
 
export const StoreProvider = ({ children, store }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export const useStore = () => React.useContext(StoreContext);

export const withStore = (Component) => (props) => {
  return <Component {...props} store={useStore()} />;
};
// #endregion
