import React from 'react';
import './style.scss';
import { FaEye } from 'react-icons/fa'

function PasswordInput({onChange, value, placeholder, type, onClick}) {


    return (
        <div class="input-contain-password">
            <input type={type} className='passwordInput' value={value} id="fname" name="fname" autocomplete="off" aria-labelledby="placeholder-fname" onChange={onChange} style={{paddingRight: '8%'}} required/>
            <label class="placeholder-text-password" for="fname" id="placeholder-fname">
                <div class="text">{placeholder}</div>
            </label>
            <FaEye className='passwordViewIcon' onClick={onClick}/>
        </div>
)
    
    
}

export default PasswordInput;