import React from 'react';
import './style.scss';

function Button({onClick, placeholder}) {



    return (
        <div className='btn' onClick={onClick}>{placeholder}</div>
)
    
    
}

export default Button;