import React from 'react';
import './style.scss';
import { FaArrowLeft } from 'react-icons/fa'

function BackButton({onClick, placeholder}) {



    return (
        <div className='backButton' onClick={onClick}>
            <FaArrowLeft/>
            <p>Back</p>
        </div>
)
    
    
}

export default BackButton;