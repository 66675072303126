import React, {useState,useEffect} from 'react';
import { useNavigate, useParams }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { View } from '@aws-amplify/ui-react';
import { BackButton, Card, Button, Select, Input, PasswordInput } from '../../components';
import './style.scss'

const Withdraw = observer(() => {
    let navigate = useNavigate();
    const commonStore = useStore();
    const {amnt} = useParams();

    const [withdrawalAmount, setWithdrawalAmount] = useState(0);
    const [transactionFee, setTransactionFee] = useState(0);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [method, setMethod] = useState('');
    const [error, setError] = useState('');
    const [authMethod, setAuthMethod] = useState('webauthn');
    const [webauthnFailed, setWebauthnFailed] = useState(false);
    const [password, setPassword] = useState('');
    const [PayoutMethods, setPayoutMethods] = useState([]);
    const [PasswordType, setPasswordType] = useState('password');

    useEffect(() => {
        loadData();
    }, []);

    function loadData(){
        
        let amt = amnt;
        amt = amt.replace('=', '');
        console.log(amt);
        setWithdrawalAmount(Number(amt));
        let perc = commonStore.employeeDetail.employeesalary.FeeAmount || 3;
        if (perc < 2.5){
            perc = 2.5;
        }
        setTransactionFee(Number((Number(amt) * perc / 100).toFixed(2)));
        let accounts = commonStore.retrieveBankAccounts();
        if (accounts === '[]'){
            accounts = [];
        }
        setBankAccounts(accounts);
        let tmpMethod = [];
        if (commonStore.employeeDetail.bankaccount){
            tmpMethod.push({
                value: 'primary',
                description: `${commonStore.employeeDetail.bankaccount.bankname} ****${commonStore.employeeDetail.bankaccount.accountnumber.substr(commonStore.employeeDetail.bankaccount.accountnumber.length -4)}`
            })
        }
        if (commonStore.employeeDetail.secondaryBankAccount){
            tmpMethod.push({
                value: 'secondary',
                description: `${commonStore.employeeDetail.secondaryBankAccount.bankname} ****${commonStore.employeeDetail.secondaryBankAccount.accountnumber.substr(commonStore.employeeDetail.secondaryBankAccount.accountnumber.length -4)}`
            })
        }
        setPayoutMethods(tmpMethod);
    }

    function optionSelected(e){
        setMethod(e.target.value);
    }

    function addBankAccount(){
        navigate('/newbankaccount', {amount: withdrawalAmount});
    }

    function passwordChanged(e){
        setPassword(e.target.value);
    }

    function goBack(){
        navigate('/dashboard')
    }

    function usePassword(){
        setAuthMethod('password');
    }

    function processWebauthn(){
        commonStore.setLoading(true);
        commonStore.authenticateAdvanceWebauthn().then((ret) => {
            if (ret === 'Password'){
                usePassword();
                commonStore.setLoading(false);
            }else if(ret === true){
                if (method === 'primary' || method === 'secondary'){
                    processBankAccount();
                }else{
                    console.log('No method selected');
                    commonStore.setLoading(false);
                }
            }else{
                setError('Authentication Failed');
                commonStore.setLoading(false);
            }
        });
    }
    function processPassword(){
        commonStore.setLoading(true);
        commonStore.authenticateAdvancePassword(password).then((ret) => {
            if (ret === true){
                if (method === 'primary' || method === 'secondary'){
                    processBankAccount();
                }else{
                    console.log('No method selected');
                    commonStore.setLoading(false);
                }
            }else{
                setError('Authentication Failed');
                commonStore.setLoading(false);
            }
        });
        
    }

    function processBankAccount(){
        let data = {
            bankAccount: method,
            totalAmount: parseFloat(withdrawalAmount) + parseFloat(transactionFee),
            advanceAmount: parseFloat(withdrawalAmount),
            feeAmount: parseFloat(transactionFee)
        };
        commonStore.processBankAccountAdvance(data).then((ret) => {
            if (ret === true || ret === 'True' || ret === 'true'){
                commonStore.setLoading(false);
                navigate('/withdrawsuccess');
            }else if (ret === false || ret === 'False' || ret === 'false'){
                setError('An error has occured, please try again.');
                commonStore.setLoading(false);
            }else{
                setError(ret);
                commonStore.setLoading(false);
            }
        })
    }

    function showPassword(){
        if (PasswordType === 'password'){
            setPasswordType('text');
        }else{
            setPasswordType('password');
        }
    }


    if (isMobile()){
        return (
            <View className='pageView withdraw'>
                <div style={{width: '100%'}}>
                    <BackButton onClick={goBack}/>
                </div>
                <Card className='withdrawCard'>
                    <div className='accessYourPayDiv'>
                        <p>Confirm withdrawal</p>
                    </div>
                    <div className='accessYourPayHr'></div>
                    <div className='withdrawTable'>
                        <div className='withdrawTableRow'>
                            <p>Advance Amount</p>
                            <p>{`R${withdrawalAmount}`}</p>
                        </div>
                        <div className='withdrawTableRow'>
                            <p>Transaction fee</p>
                            <p>{`R${transactionFee}`}</p>
                        </div>
                        <div className='withdrawTableRow'>
                            <p>Deduction from next pay</p>
                            <p>{`R${(parseFloat(transactionFee) + parseFloat(withdrawalAmount)).toFixed(2)}`}</p>
                        </div>
                        <div className='withdrawTableRow'>
                            <p>Date</p>
                            <p>{`${(new Date()).getDate().toString()} ${(new Date()).toLocaleString('default', { month: 'short' })} ${(new Date()).getFullYear().toString()}`}</p>
                        </div>
                    </div>
                    {(!commonStore.employeeDetail.bankaccount && !commonStore.employeeDetail.secondaryBankAccount) ? 
                    <p>You haven't added any bank accounts yet.</p> 
                    : 
                    <Select placeholder='Payout Method' options={PayoutMethods} onChange={optionSelected} value={method}/>
                    }
                    

                    {(authMethod === 'webauthn')?
                    <>
                    {(method === '')?
                    null:<>{(error.length > 0)? <p style={{color: 'red'}}>{error}</p>:null}<Button onClick={processWebauthn} placeholder='Confirm withdrawal'/><Button onClick={usePassword} placeholder='Use Password'/></>
                    }
                    </>: 
                    <>
                    {(method === '')?
                    null:<>
                    <PasswordInput type={PasswordType} value={password} onChange={passwordChanged} placeholder='Password' onClick={showPassword}/>
                    {(error.length > 0)? <p style={{color: 'red'}}>{error}</p>:null}
                    <Button onClick={processPassword} placeholder='Confirm withdrawal'>Confirm withdrawal</Button></>
                    }
                    </>}
                </Card>
            </View>
        )
    }else{
        return (
            <View className='pageView dashboard'>

            </View>
        )
    }
})

export default Withdraw
