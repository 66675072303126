import React from 'react';
import './style.scss';

function Select({onChange, value, placeholder, bg='#1A1B1F', options=[]}) {
    console.log(options)


    return (
        <div class="select-contain">
            {/* <input type={type} value={value} id="fname" name="fname" autocomplete="off" aria-labelledby="placeholder-fname" onChange={onChange} style={{backgroundColor:bg}} required/> */}
            <select style={{backgroundColor:bg}} onChange={onChange} value={value}>
                <option value='' id="fname" name="fname">Please Select</option>
                {options.map((opt, index) => {
                    return(
                        <option value={opt.value} id="fname" >{opt.description}</option>
                    )
                })}
            </select>
            <label class="placeholder-text" for="fname" id="placeholder-fname">
                <div class="text">{placeholder}</div>
            </label>
        </div>
)
    
    
}

export default Select;