import React from 'react';
import './style.scss';

function Card({children, onClick, className, id, style={}}) {


    let customStyle = style;

    return (
      <div className={`card ${className}`} style={customStyle} id={id} onClick={onClick}>
        {children}
      </div>
    )
    
    
}
    
    

export default Card;