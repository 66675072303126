import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { View } from '@aws-amplify/ui-react';
import './style.scss'
import { Card, Input, Button } from '../../../components';

const Home = observer(() => {
    let navigate = useNavigate();
    const commonStore = useStore();

    const [withdrawalAmount, setWithdrawalAmount] = useState(null);
    const [amountAvailable, setAmountAvailable] = useState(1);
    const [amountAllowed, setAmountAllowed] = useState(0);
    const [history, setHistory] = useState([]);

    useEffect(() => {
        getHistory();
    }, []);

    function getHistory(){
        commonStore.setLoading(true);
        commonStore.getAdvanceHistory().then((ret) => {
            setAmountAvailable(ret.amountAvailable);
            setAmountAllowed(ret.amountAllowed);
            let advHist = commonStore.advanceHistories;
            advHist.sort((b, a) => new Date(a.AdvanceRequestDate) - new Date(b.AdvanceRequestDate));
            setHistory(advHist);
            console.log(advHist);
            commonStore.setLoading(false);
        });
    }

    function withdrawalAmountChanged(e){
        console.log(e.target.value)
        if (e.target.value === ''){
            setWithdrawalAmount(null);
        }else{
            setWithdrawalAmount(Number(e.target.value));
        }
        
    }

    function withdraw(){
        if (withdrawalAmount === null){
            window.alert('Please enter an amount.');
        }else if (isNaN(withdrawalAmount) || isNaN(parseFloat(withdrawalAmount))){
            window.alert('Please enter an amount.');
        }else if(parseFloat(withdrawalAmount) > parseFloat(amountAllowed)){
            window.alert('The amount entered and  exceeds the amount you have available.');
        }else if ((parseFloat(withdrawalAmount)* 1.03) > parseFloat(amountAllowed)){
            window.alert('The amount entered and transaction fee exceeds the amount you have available.');
        }else if (parseFloat(withdrawalAmount) <= 0){
            window.alert('Please enter an amount greater than 0.');
        }else{
            commonStore.setLoading(true);
            commonStore.loadBankAccounts().then((ret) => {
                // navigate({pathname: '/withdraw', search: withdrawalAmount});
                commonStore.setLoading(false);
                navigate({pathname: `/withdraw/${withdrawalAmount}`});
            })
            
        }
    }


    if (isMobile()) {
        return(
            <div className='homeTab'>
                <Card>
                    <div className='accessYourPayDiv'>
                        <p>Access your pay</p>
                    </div>
                    <div className='accessYourPayHr'></div>
                    <div className='withdrawalDiv'>
                        <svg className='balanceGuage' viewBox="13 0 100 75">
                            <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="100%">
                                <stop offset="0%" stopColor="#56c4fb" />
                                <stop offset="100%" stopColor="#0baeff" />
                            </linearGradient>
                            <path className="grey" d="M22 61A40 40 0 11104 61" fill='none' />
                            <path id="blue" fill='none' strokeDashoffset={130 - (130 * (parseInt(amountAllowed)/parseInt(amountAvailable)))} className="blue" d="M22 61A40 40 0 11104 61" />
                            <text className="withdrawTxtHead" x="64%" y="65%" dominantBaseline="middle" textAnchor="middle">{`R${amountAllowed}`}</text>
                            <text className="withdrawTxtSub" x="64%" y="80%" dominantBaseline="middle" textAnchor="middle">Available balance</text>
                        </svg>
                    
                        <Input type='number' value={String(withdrawalAmount)} bg='#000000' onChange={withdrawalAmountChanged} placeholder='Withdrawal amount'/>
                        <Button onClick={withdraw} placeholder='Withdraw'/>
                    </div>
                </Card>
            </div>
        )
    }else{
        return(
            <div></div>
        )
    }
})

export default Home