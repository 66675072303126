import React from 'react';
import './style.scss';

function Input({onChange, value, placeholder, type, bg='#1A1B1F'}) {



    return (
        <div class="input-contain">
            <input type={type} value={value} id="fname" name="fname" autocomplete="off" aria-labelledby="placeholder-fname" onChange={onChange} style={{backgroundColor:bg}} required/>
            <label class="placeholder-text" for="fname" id="placeholder-fname">
                <div class="text">{placeholder}</div>
            </label>
        </div>
)
    
    
}

export default Input;