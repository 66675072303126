import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate
} from "react-router-dom";
import { View } from '@aws-amplify/ui-react';
import { useStore } from './stores/Store';
import '@aws-amplify/ui-react/styles.css';
import { LoadingScreen } from './components';
import SignIn from './pages/signin'; 
import Dashboard from './pages/dash';
import Withdraw from './pages/withdraw';
import WithdrawSuccess from './pages/withdraw/withdrawSuccess';

const App = (() => {
  const store = useStore();
  // useEffect(() => {
    
  // }, []);
  return (
    <Router>
      {/* <div className="App"> */}
      <View as="div" className="App" backgroundColor="#0C0C0E" height={`${window.innerHeight}px`} color='#ffffff' >
        <LoadingScreen/>
        <Routes>
          <Route path="/signin" element={<SignIn/>}/>
          {/* Place in private router */}
          <Route path="/dashboard" element={<Dashboard/>}/>
          <Route path="/withdraw/:amnt" element={<Withdraw/>}/>
          <Route path="/withdrawsuccess" element={<WithdrawSuccess/>}/>

          <Route path='/' element={<Navigate to='/signin'/>}/>
          <Route path='*' element={<Navigate to='/signin'/>}/>
        </Routes>
      </View>
      {/* </div> */}
    </Router>
  );
})

export default App;
