import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { View } from '@aws-amplify/ui-react';
import './style.scss'
import { Card, Input, Button } from '../../../components';

const WithdrawSuccess = observer(() => {
    let navigate = useNavigate();
    const commonStore = useStore();

    function goToDashboard(){
        navigate('/dashboard');
    }


    if (isMobile()) {
        return(
            <View className='pageView withdraw'>
                <img className='withdrawSuccessImage' src='https://d1ap40fa3o8mik.cloudfront.net/siteAssets/AtomicOutline.svg' />
                <p className='headerText' style={{marginBottom: '6px'}}>Success</p>
                <p className='subtitleText'>Your payment was successful. Please note that the amount will take a few hours to reflect in your bank account.</p>
                <Button onClick={goToDashboard} placeholder='Return to Dashboard'/>
            </View>
        )
    }else{
        return(
            <div></div>
        )
    }
})

export default WithdrawSuccess