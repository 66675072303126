import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { View } from '@aws-amplify/ui-react';
import './style.scss'
import { GoHome } from 'react-icons/go';
import { CgProfile } from 'react-icons/cg';

import Home from './home';

const Dashboard = observer(() => {
    let navigate = useNavigate();
    const commonStore = useStore();
    const [ActivePage, setActivePage] = useState('Home');

    function tabSelected(e){
        setActivePage(e.currentTarget.id);
    }

    if (isMobile()){
        return (
            <View className='pageView dashboard'>
                <div className='dashboardTopBar'>
                    <img className='topBarLogo' src='https://d1ap40fa3o8mik.cloudfront.net/siteAssets/atomicLogoLong.svg'/>
                    {/* <FaBars style={{height: '16px'}} onClick={toggleSidebar}/> */}
                </div>
                <div className='dashboardContent'>
                    {(ActivePage === 'Home') ? <Home/> : null}
                </div>
                <div className='dashboardTabbar'>
                    <div className={`dashboardTabBarItem ${(ActivePage === 'Home')? 'dashboardTabBarItemActive' : ''}`} id='Home' onClick={tabSelected}>
                        <div className='dashboardTabBarItemIconContainer'>
                            <GoHome className='dashboardTabBarItemIcon' />
                        </div>
                        <p className='dashboardTabBarItemText'>Home</p>
                    </div>

                    <div className={`dashboardTabBarItem ${(ActivePage === 'Profile')? 'dashboardTabBarItemActive' : ''}`} id='Profile' onClick={tabSelected}>
                        <div className='dashboardTabBarItemIconContainer'>
                            <CgProfile className='dashboardTabBarItemIcon' />
                        </div>
                        <p className='dashboardTabBarItemText'>Profile</p>
                    </div>

                </div>


            </View>
        )
    }else{
        return (
            <View className='pageView dashboard'>

            </View>
        )
    }
})

export default Dashboard
